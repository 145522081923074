/* Styles for the circular icon container */
.icon-container {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 65px;
    border-radius: 50%;
    overflow: hidden;
    padding: 4px; /* Add padding to create some space around the image */
}

.icon-image {
    width: auto; /* Change from 100% to auto */
    height: auto; /* Change from 100% to auto */
    max-width: 100%; /* Ensure image doesn't overflow */
    max-height: 100%; /* Ensure image doesn't overflow */
    object-fit: contain; /* Change from cover to contain */
    border-radius: 50%;
}
/* If some logos need specific adjustments, you can target them by adding a class */
.icon-image[src*="istech"] {
    width: 75%;  /* Specific adjustment for istech logo if needed */
}

.icon-image[src*="cbi"] {
    width: 75%;  /* Specific adjustment for cbi logo if needed */
}
.icon-image[src*="ppl"] {
    width: 80%;  /* Specific adjustment for ppl logo if needed */
}